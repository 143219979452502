<template>
  <div class="n-page pc">
    <div class="n-page-content">
      <!-- 左侧分类树 -->
      <div class="n-categories">
        <div class="title">{{$t('layout.news')}}</div>
        <el-tree class="category-tree" :data="menus" @current-change="changeType"
                 node-key="id" :current-node-key="checkMenu"></el-tree>
      </div>

      <!-- 右侧产品列表 -->
      <div class="n-products">
        <!-- 面包屑导航 -->
        <el-breadcrumb>
          <el-breadcrumb-item>
            <router-link to="/">{{$t('layout.homePage')}}</router-link>
          </el-breadcrumb-item>
          <el-breadcrumb-item>{{$t('layout.news')}}</el-breadcrumb-item>
        </el-breadcrumb>

        <div class="product-table">
          <div v-for="news in queryResult.records" v-bind:key="news.newsId" class="product">
            <el-row>
              <el-col :span="12" @click="$router.push({path: '/news/news-detail',query: {newsId: news.newsId}})"
                      style="font-size: 18px;font-weight: bold;overflow: hidden;word-break: break-all; text-overflow:ellipsis;white-space: nowrap;">
                <span @click="$router.push({path: '/news/news-detail',query: {newsId: news.newsId}})">
                  {{$i18n.locale === 'zh-CN' ? news.zhTitle : news.enTitle}}
                </span>
              </el-col>
              <el-col :span="8" :offset="4" style="font-size: 15px;text-align: right">{{news.gmtCreated}}</el-col>
            </el-row>
            <el-row style="margin-top: 10px;">
              <span @click="$router.push({path: '/news/news-detail',query: {newsId: news.newsId}})" class="news-content">
                {{$i18n.locale === 'zh-CN' ? news.zhContent : news.enContent}}
              </span>
            </el-row>
          </div>
        </div>

        <!-- 分页 -->
        <el-pagination background layout="prev, pager, next" :total="queryResult.total"
                       @current-change="currentPageChange"
                       @prev-click="currentPageChange(queryParam.current - 1)"
                       @next-click="currentPageChange(queryParam.current + 1)">
        </el-pagination>
      </div>
    </div>
  </div>
</template>


<script>

  export default {

    data() {
      return {
        menus: [],
        checkMenu: '',

        news: [],

        queryParam: {
          current: 1,
          size: 10,
          total: 0,
          type: '',
        },
        queryResult: {
          records: [],
          total: 1,
          current: 1,
        },
      };
    },

    created() {
      window.page = this;
      this.menus = [{
        id: this.$t('layout.companyNews'),
        label: this.$t('layout.companyNews'),
      }, {
        id: this.$t('layout.industryNews'),
        label: this.$t('layout.industryNews'),
      }];

      this.checkMenu = this.$route.query.type;
      this.query();
    },
    methods: {
      query() {
        const loading = this.$loading();

        const type = this.$route.query.type;
        this.queryParam.type = type === this.$t('layout.companyNews') ? '公司要闻' : '行业资讯';

        this.$ajax.get('/news', {params: this.queryParam}).then(res => {
          if (res.code !== 100) {
            return;
          }

          this.queryResult = res.data;
          for (let record of this.queryResult.records) {
            record.zhContent = this.decodeContent(record.zhContent);
            record.enContent = this.decodeContent(record.enContent);
          }
          this.queryParam.total = this.queryResult.total;
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        }).finally(() => loading.close());
      },

      decodeContent(content) {
        content = content.replace(/<[^>]+>/g, "");
        content = content.replace(/&amp;/g, "&");
        content = content.replace(/&lt;/g, "<");
        content = content.replace(/&gt;/g, ">");
        content = content.replace(/&nbsp;/g, "");
        content = content.replace(/&#39;/g, "'");
        content = content.replace(/&quot;/g, "\"");
        return content;
      },

      changeType(a, b, c) {
        this.$router.replace({
          path: this.$route.path,
          query: {
            type: a.label
          }
        }).then(() => {
          this.queryParam = {
            current: 1,
            size: 10,
            total: 0,
            type: '',
          };
          this.queryParam.type = a.label;
          this.query();
        })
      },

      currentPageChange(currentPage) {
        this.queryParam.current = currentPage;
        this.query();
      },
    }

  }
</script>


<style lang="scss">
  .n-page {
    background-color: var(--white);
  }

  .n-page-content {
    width: 100%;
    max-width: 1128px;
    margin: 0 auto;

    display: flex;
    justify-content: center;

    padding-top: 100px;
    padding-bottom: 200px;
  }

  .n-categories {
    min-width: 300px;
    padding-left: 50px;

    .title {
      font-size: 34px;
      font-weight: 700;
      color: var(--black);
    }
    .category-tree {
      margin-top: 30px;
      overflow: auto;

      .el-tree-node.is-current {
        .el-tree-node__content {
          color: var(--white);
          background-color: rgb(179, 216, 255);
        }
      }

      .el-tree-node__content {
        height: 40px;
        .el-tree-node__label {
          font-size: 16px;
        }
      }
      .el-tree-node__content:hover {
        color: var(--white);
        background-color: #409EFF;
      }
    }
  }

  .n-products {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    flex: 1;
    max-width: 800px;
    margin-left: 35px;

    .el-breadcrumb {
      height: 48px;
      margin-left: 15px;
      line-height: 60px;
    }

    .product-table {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 15px;

      .product {
        width: 100%;
        margin-bottom: 40px;
        position: relative;
        cursor: pointer;

        .name {
          height: 50px;
          line-height: 50px;
          font-size: 16px;
          text-align: center;
          color: var(--black);
          background-color: rgb(242, 242, 242);

          position: absolute;
          bottom: 15px;
          left: 20px;
          right: 20px;
        }

        .news-content {
          font-size: 15px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          line-height: 20px;
          height: 40px;
          word-break: break-all;
        }
      }
    }

    .el-pagination {
      margin-top: 50px;
      text-align: center;
    }
  }
</style>
